
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  components: {},
})

export default class SalesSummaryReport extends mixins(UtilityOptions) {
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Sales Summary Report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "Hour",
    filterBranch: 0,
  };

  private filterList = [
    {
      'name': 'Hour',
    },
    {
      'name': 'Month',
    },
    {
      'name': 'Year',
    }
  ];

  private filterDates = [];
  private salesLabel = [];
  private timeSlot = [];
  private dialogTitle;
  private filterBranch:any = [];

  private multiAxisOptions = {
    indexAxis: 'x',
    responsive: true,
    responsiveAnimationDuration: 500,
  };

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.dialogTitle = "Filter sales";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading         = true;
    this.reportService.salesSummaryReport(this.searchAssociate).then((res) => {
      const data       = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.timeSlot   = data.record[0];
      this.salesLabel = data.record[1];
      this.loading     = false;
    });
    this.productDialog   = false;
  }

  get multiAxisData() {
    return {
      labels: this.timeSlot,
      datasets: [
        {
          label: "Sales Amount $",
          backgroundColor: "#004C97",
          yAxisID: "y-axis-1",
          data: this.salesLabel,
        },
      ],
    };
  }
}
