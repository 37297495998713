
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import moment from "moment";

interface ItemList {
  ctr: number;
  mall: string;
  code: number;
  service: string;
  adj: number;
  total: number;
}

@Options({
  components: {},
})
export default class ServiceSummaryReport extends mixins(UtilityOptions) {
  private lists: ItemList[] = [];
  private reportService;
  private counter = 0;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Report", to: "report" },
    { label: "Service summary report" },
  ];

  private searchAssociate = {
    id: "",
    dateFrom: "",
    dateTo: "",
    filter: "None",
    filterBranch: 0,
  };

  private multiAxisOptions = {
    responsive: true,
    responsiveAnimationDuration: 500,
  };

  private filterDates = [];
  private serviceList = [];
  private totalList = [];
  private dialogTitle;
  private filterBranch: any = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.dialogTitle = "Filter Transaction";
    this.productDialog = true;
  }

  //USED TO LOAD SEARCH DIALOG BOX DATA
  storeList() {
    this.reportService.storeList().then((res) => {
      const data = this.camelizeKeys(res);
      this.filterBranch = data.branchesList;
      this.filterDates = data.filterList;
    });
  }

  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.dailyService(this.searchAssociate).then((res) => {
      const data = this.camelizeKeys(res);
      this.resultTitle = data.resultTitle;
      this.lists = data.record;
      this.loading = false;
    });
    this.productDialog = false;
  }

  get getDateTime() {
    return (
      "Printed at " +
      moment().format("DD-MMM-YYYY") +
      " / " +
      moment().format("hh:mm A")
    );
  }

  get multiAxisData() {
    return {
      labels: this.sList,
      datasets: [
        {
          label: "Sales Amount $",
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726",
            "#52D726",
            "#FFEC00",
            "#7CDDDD",
          ],
          hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D,#c00"],
          data: this.tList,
        },
      ],
    };
  }

  get sList() {
    let s: any = [];
    this.lists.forEach((e) => {
      s.push(e.service);
    });
    return s;
  }

  get tList() {
    let i: any = [];
    this.lists.forEach((e) => {
      i.push(e.total);
    });

    return i;
  }

  get netSales() {
    const sum = this.tList.reduce(function(a, b) {
      return a + b;
    }, 0);
    return sum;
  }
}
